import { environment } from '../../../environments/environment';
export const BASE_URL: string = environment.baseUrl;
export const COOKIE_URL: string = environment.cookieURL
export const COOKIE_SUFFIX: string = environment.cookieSuffix
export const ILLUMINAIRE_URL: string = environment.illuminaire; 
export const isProdEnv: boolean = environment.production;
export const API_UPLOAD_KML_FILE: string = `${BASE_URL}/api/v1/region/upload_kml`;

export const API_URL_GET_ALL_RIDER_REVIEW: string = `${BASE_URL}/api/v1/get/review`
export const API_URL_RIDER_REVIEW_BY_RIDE_REQUEST_ID: string = `${BASE_URL}/api/v1/get/review`
export const API_URL_ADD_REVIEW: string = `${BASE_URL}/api/v1/add/review`
export const API_URL_GET_RIDER_DETAILS: string = `${BASE_URL}/api/v1/rider/all/riders/profile`
export const API_URL_SYNC_RIDER_DATA: string = `${BASE_URL}/api/v1/rider/generate/rider/profile/`;
export const API_URL_GET_WAITING_TIME_SUMMARY: string = `${BASE_URL}/api/v1/rider/elite/ride/details/waitingtime`;
export const API_URL_GET_REFUND_SUMMARY: string = `${BASE_URL}/api/v1/rider/elite/ride/details/refund`;

export const API_URL_GET_ALL_ZONES: string = `${BASE_URL}/api/v1/user/fetch/zone/`;

export const API_GET_ALL_LIVE_ALERTS: string = `${BASE_URL}/api/v1/homeCharging/liveAlerts`;
export const API_GET_ALL_PAST_ALERTS: string = `${BASE_URL}/api/v1/homeCharging/pastAlerts`;
export const API_GET_ALL_HOME_CHARGING_DP: string = `${BASE_URL}/api/v1/homeCharging/drivers`;
export const API_GET_ALERT_DETAILS: string = `${BASE_URL}/api/v1/homeCharging/alert/details/`;
export const API_CLOSE_REOPEN_LIVE_ALERT: string = `${BASE_URL}/api/v1/homeCharging/close/alerts`;


export const API_URL_LIST_DRIVERS: string = `${BASE_URL}/api/v1/driver/all/drivers`;
export const API_URL_GET_DRIVER_LOCATION: string = `${BASE_URL}/api/v1/driver`;
export const API_URL_GET_ALL_CONFIGURE_RULES: string = `${BASE_URL}/api/v1/rules/fetch_all`;
export const API_URL_GET_RULES_ID_WISE: string = `${BASE_URL}/api/v1/rules/fetch_one?`;
export const API_URL_INSERT_CONFIGURATION_RULE: string = `${BASE_URL}/api/v1/rules/insert`;
export const API_URL_UPDATE_CONFIGURATION_RULE: string = `${BASE_URL}/api/v1/rules/update`;
export const API_URL_GET_DRIVER_DETAILS: string =`${BASE_URL}/api/v1/internal/driver/details/`;

export const API_URL_ADD_DRIVER: string = `${BASE_URL}/api/v1/driver/save`;
export const API_URL_EDIT_DRIVER: string = `${BASE_URL}/api/v1/driver/edit`;
export const API_URL_GET_PUNCH_ID: string = `${BASE_URL}/api/v1/driver/get/data`

export const API_URL_RESET_PASSWORD_DRIVER: string = `${BASE_URL}/api/v1/driver/reset-password`;

export const API_URL_MARK_BREAKDOWN_DRIVER: string = `${BASE_URL}/api/v1/driver/mark/breakdown`;

export const API_URL_LIST_VEHICLE: string = `${BASE_URL}/api/v1/vehicle/list`;
export const API_URL_LIST_VEHICLE_ALL: string = `${BASE_URL}/api/v1/vehicle/all/vehicles`;
export const API_URL_LIVE_MAP: string = `${BASE_URL}/api/v1/driver/live/map`;
export const API_URL_LIVE_MAP_BETA_STATS: string = `${BASE_URL}/api/v2/live/map/all/stat`;
export const API_URL_LIVE_MAP_BETA_STATS_V3: string = `${BASE_URL}/api/v2/live/map/all/stat/v3`;

export const API_URL_LIVE_MAP_BETA_DRIVER_DETAILS: string = `${BASE_URL}/api/v2/live/map/driver/details/`;
export const API_URL_LIVE_MAP_BETA_DRIVERS_LIST: string = `${BASE_URL}/api/v2/live/map/drivers/list/`;
export const API_URL_LIVE_MAP_BETA_AIRPORT_DRIVERS_COUNT: string = `${BASE_URL}/api/v2/live/map/drivers/near/airport`;
export const API_URL_LIVE_MAP_BETA_DRIVERS_SEARCH: string = `${BASE_URL}/api/v2/live/map/search`;
// export const API_URL_LIVE_MAP: string = `https://run.mocky.io/v3/9af1166e-e992-42c3-befd-18a1294cb8e7`;

export const API_URL_USER_HUB_PERMISSIONS = `${BASE_URL}/api/v1/hub/get/permissions`;

export const API_HIGH_DELAY_REGIONS = `${BASE_URL}/api/v1/ride/region/delays`;
export const API_URL_ADD_VEHICLE: string = `${BASE_URL}/api/v1/vehicle/save`;
export const API_URL_EDIT_VEHICLE: string = `${BASE_URL}/api/v1/vehicle/edit`;

export const API_URL_GET_VEHICLE_REGISTRATION = `${BASE_URL}/api/v1/vehicle/get/data?`
export const API_URL_PASTRIDE_CSV_DOWNLOAD = `${BASE_URL}/api/v1/ride/past/rides/csv`
export const API_URL_PASTRIDE_CSV = `${BASE_URL}/api/v1/ride/past/rides/csv/generate`
export const API_URL_REPORT_RIDER_FEEDBACK_CSV_DOWNLOAD = `${BASE_URL}/api/v1/report/rider/rating/feedback/csv`
export const API_URL_DELETED_USERS_CSV_DOWNLOAD = `${BASE_URL}/api/v1/rider/deleted/users/csv/get`
export const API_URL_REJOINED_USERS_CSV_DOWNLOAD = `${BASE_URL}/api/v1/rider/rejoined/users/csv/get`

export const API_URL_LOGIN: string = `${BASE_URL}/api/v1/auth/login`;
export const API_URL_LOGOUT: string = `${BASE_URL}/api/v1/auth/logout`;
export const API_URL_CHECK_TOKEN: string = `${BASE_URL}/api/v1/auth/check/token`;

export const API_URL_LIST_LIVE_RIDE = `${BASE_URL}/api/v1/ride/all/rides/live/v3`;
export const API_URL_LIST_LIVE_RIDE_BETA = `${BASE_URL}/api/v1/ride/all/rides/live/v3`;
export const API_URL_LIST_LIVE_RIDE_BETA_NEW = `${BASE_URL}/api/v1/live/ride/all`;
export const API_URL_LIST_SCHEDULED_RIDE = `${BASE_URL}/api/v1/ride/all/rides/scheduled`;
export const API_URL_LAST_RIDE_DETAIL = `${BASE_URL}/api/v1/ride/previousDriverDetails`;

export const API_URL_MARK_CUSTOMER_ARRIVED = `${BASE_URL}/api/v1/mark/customer/arrival`;
export const API_URL_ACTION_LIVE_RIDE = `${BASE_URL}/api/v1`;
export const API_URL_CANCEL_BREAKDOWN = `${BASE_URL}/api/v1`;
export const API_URL_GET_NOTIFICATION_CONTENT = `${BASE_URL}/api/v1/ride/get/notification-content`;
export const API_URL_SEND_NOTIFICATION =`${BASE_URL}/api/v1/ride/send/alert`

export const API_URL_PICKUP_NOTES_HISTORY = `${BASE_URL}/api/v1/ride/pickup/notes`;
export const API_URL_PAST_RIDE_LIST = `${BASE_URL}/api/v1/ride/all/rides`;
export const API_URL_PAST_RIDE_STATS = `${BASE_URL}/api/v1/ride/all/rides/stats`;
export const API_URL_PAST_RIDE_STATS_ZONE = `${BASE_URL}/api/v1/ride/all/rides/stats/zone`;
export const API_URL_RIDE_DELAY_ALERT = `${BASE_URL}/api/v1/ride/action/ride-delays`;
export const API_URL_SAVE_ELIGIBLE_TIMESTAMP = `${BASE_URL}/api/v1/ride/save/eligible-timestamp`;
export const API_URL_GET_ELIGIBLE_TIMESTAMPS = `${BASE_URL}/api/v1/ride/eligible-timestamp`;
export const API_URL_RIDE_DELAY_ALERT_STATUS = `${BASE_URL}/api/v1/ride/get/ride-delays/status`;

export const API_URL_CUSTOM_SEARCH = `${BASE_URL}/api/v1/search/`;
export const API_URL_ELASTIC_SEARCH = `${BASE_URL}/api/v1/search/elastic/`;
export const API_URL_CUSTOM_MAP_RIDE_SEARCH = `${BASE_URL}/api/v2/live/map/`;


// export const API_URL_STATS_LIVE = `${BASE_URL}/api/v1/stats/live`;
export const API_URL_STATS_LIVE = `${BASE_URL}/api/v1/stats/live/zone`;
export const API_URL_STATS_LIVE_V3 = `${BASE_URL}/api/v1/stats/live/zone/v3`;
export const API_URL_STATS_LIVE_V4 = `${BASE_URL}/api/v1/stats/live/zone/v4`;
export const API_URL_SCHEDULED_RIDE_STAT=`${BASE_URL}/api/v1/stats/scheduled/new`;
export const API_URL_SCHEDULED_RIDE_SLOT=`${BASE_URL}/api/v1/slots/get/scheduled/ride/slot`
export const API_URL_RIDE_DETAILS = `${BASE_URL}/api/v1/ride/details`;
export const API_URL_RIDE_DETAILS_FOR_OTP = `${BASE_URL}/api/v1/:otp/details`;
export const API_URL_CONFIRM_RIDE_FOR_OTP = `${BASE_URL}/api/v1/:otp/confirm`;
export const API_URL_PROVISIONAL_AMT =`${BASE_URL}/api/v1/provisional/amount`;
export const API_URL_RIDE_ETA = `${BASE_URL}/api/v1/ride/updated/eta`;
export const API_URL_GET_SUB_PLACE =`${BASE_URL}/api/v1/ride/get/sub/place`;

export const API_URL_DRIVER_ON_DUTY = `${BASE_URL}/api/v1/driver/on/duty`;
export const API_URL_DRIVER_ON_DUTY_STATS = `${BASE_URL}/api/v1/driver/on/duty/stats`;
export const API_URL_DRIVER_FOR_ASSIGNMENT = `${BASE_URL}/api/v1/driver/for/assignment/v2`;
export const API_URL_DRIVER_BY_DRIVER_ID = `${BASE_URL}/api/v1/driver`;
export const API_URL_REJECT_RIDE_DENY_REQUEST =`${BASE_URL}/api/v1/driver/reject/assignment`;
export const API_URL_RESOLVE_RIDE_DENY_REQUEST = `${BASE_URL}/api/v1/driver/resolve/rfs`;

// export const API_URL_DRIVER_ON_DUTY = `https://run.mocky.io/v3/20f7a4b2-bc94-4e13-aaa3-ab6df10bce63`;
export const API_URL_DRIVER_OFFLINE = `${BASE_URL}/api/v1`;
export const API_URL_DRIVER_ONLINE=`${BASE_URL}/api/v1/driver/mark/online?driverId=`;
export const API_URL_CREATE_LIVE_RIDE = `${BASE_URL}/api/v1/ride/request`;
export const API_URL_ACTION_ASSIGN_PHANTOM_RIDE =`${BASE_URL}/api/v1`;
export const API_ASSIGN_PHANTOM_RIDE =`${BASE_URL}/supply-region/create/phantom-ride/`;
export const API_ASSIGN_BULK_PHANTOM_RIDE =`${BASE_URL}/supply-region/create/phantom-ride/bulk/`;
export const API_URL_GET_SUPPLY_REGION = `${BASE_URL}/supply-region/get`;
export const API_URL_GET_ESTIMATE_FARE=`${BASE_URL}/api/v1/ride/estimate`
export const API_URL_GET_ESTIMATE_REFUND =`${BASE_URL}/api/v1/ride/estimate/refund`;


export const API_URL_BLOCK_RIDER = `${BASE_URL}/api/v1/rider/block/user/status`;
export const API_URL_BLOCK_RIDER_IMEI = `${BASE_URL}/api/v1/rider/block/user/imei`;
export const API_URL_SEND_OTP_RIDER = `${BASE_URL}/api/v1/rider/generate/otp`;
export const API_URL_LIST_RIDER = `${BASE_URL}/api/v1/rider/all/riders`;
export const API_URL_RIDER_DEVICE_INFORMATION = `${BASE_URL}/api/v1/rider/device/information`
export const API_URL_RIDER_INFORMATION =`${BASE_URL}/api/v1/rider/information`
export const API_URL_DRIVER_STATES_AUDIT = `${BASE_URL}/api/v1/driver/states/audit`
export const API_URL_RIDER_FEEDBACK = `${BASE_URL}/api/v1/report/rider/rating/feedback`
export const API_URL_DRIVER_TEMPERATURE = `${BASE_URL}/api/v1/driver/temp`
export const API_URL_VEHICLE_SANITIZATION_TIME = `${BASE_URL}/api/v1/vehicle/vehicle/sanitization/time`
export const API_URL_ADD_RIDER_PROMO = `${BASE_URL}/api/v1/promo/apply`;
export const API_URL_RIDER_DETAILS = `${BASE_URL}/api/v3/rider/details`;
export const API_URL_USER_DETAILS = `${BASE_URL}/api/v1/user`;
export const API_URL_GET_PRIVE_DETAILS = `${BASE_URL}/api/v1/rider/prive/details?riderId=`
export const API_URL_DEACTIVATE_PROMO = `${BASE_URL}/api/v1/promo/rider/deactivate`;

export const API_URL_ALL_PACKAGES = `${BASE_URL}/api/v1/rental/package/find/all`;
export const API_URL_GET_PACKAGE = `${BASE_URL}/api/v1/rental/package/`;

export const API_URL_GET_ROLES_FOR_USERS = `${BASE_URL}/api/v1/user/`;
export const API_URL_GET_DRIVER_QUEUE_AT_V3 = `${BASE_URL}/api/v3/live/map/drivers/at/airport`;
export const API_URL_ADD_DRIVER_IN_QUEUE_V3 = `${BASE_URL}/api/v3/live/map/add/driver/at/airport`;

//callConnectToDriver
export const API_URL_CONNECT_TO_DRIVER = `${BASE_URL}/api/v1/ride/initiate/call/new`
export const API_URL_CONNECT_TO_DRIVER_BY_ID = `${BASE_URL}/api/v1/ride/initate/call-by-driver-id`
export const API_URL_GET_DISPATCHER = `${BASE_URL}/api/v1/user/fetch/dispatcher`
//call details
export const API_URL_CALL_DETAILS = `${BASE_URL}/api/v1/internal/call/details`



//Lease Configuration API's

export const API_URL_CREATE_LEASE_CONFIG = `${BASE_URL}/api/v1/generate/lease/config`
export const API_URL_UPDATE_LEASE_CONFIG = `${BASE_URL}/api/v1/update/lease/config`
export const API_URL_DELETE_LEASE_CONFIG = `${BASE_URL}/api/v1/delete/lease/scheduler?`
export const API_URL_GET_ALL_LEASE_CONFIGURATION = `${BASE_URL}/api/v1/lease/config`

//Promo Creation Screen
export const API_URL_CREATE_PROMO_CONFIG = `${BASE_URL}/api/v2/promo/create`
export const API_URL_GET_PROMO_CONFIG = `${BASE_URL}/api/v2/promo/list`
export const API_URL_ACTIVATE_PROMO_CONFIG = `${BASE_URL}/api/v2/promo/activate`
export const API_URL_UPDATE_PROMO_CONFIG = `${BASE_URL}/api/v2/promo/update`
export const API_URL_GET_RENTAL_PACKAGES = `${BASE_URL}/api/v2/promo/packages/list`
export const API_URL_GET_BUSINESS_ACCOUNTS = `${BASE_URL}/api/v2/promo/business/accounts/list`
export const API_URL_GET_SERVICE_REGION =`${BASE_URL}/api/v2/promo/all/service/region/list`
export const API_URL_GET_SUB_REGION = `${BASE_URL}/api/v2/promo/all/pricing/region/list`
export const API_URL_GET_ZONES = `${BASE_URL}/api/v2/promo/zones/list`



// Payments API
export const API_URL_ALL_PAYMENTS_TRANSACTIONS = `${BASE_URL}/api/v1/payments/transactions/other`
export const API_URL_ALL_RIDER_BALANCE = `${BASE_URL}/api/v1/all/rider/payment`
export const API_URL_PAYMENT_ORDER = `${BASE_URL}/api/v1/payment/order`
export const API_URL_BLUWALLET_PANEL = `${BASE_URL}/api/v1/rider/bluwallet/paytm/balance`
export const API_URL_ALL_BLUWALLET_TRANSACTIONS = `${BASE_URL}/api/v1/payments/transactions/wallet`
export const API_URL_GET_PENDING_PAYMENT = `${BASE_URL}/api/v1/payments/pending`
export const API_URL_RECIEVE_PENDING_PAYMENT = `${BASE_URL}/api/v1/payments/apply/pending/payment`
export const API_URL_CANCEL_PENDING = `${BASE_URL}/api/v1/payments/mark/inactive?orderId=`
export const API_PAYMENT_STATUS = `${BASE_URL}/api/v1/payments/payment/status?gatewayOrderId=`
export const API_POS_PAYMENT_SEARCH =`${BASE_URL}/api/v1/payments/paytm/search`
export const API_POS_PAYMENT_RETRY =`${BASE_URL}/api/v1/payments/paytm/retry`

//receipt
export const API_URL_DOWNLOAD_RECEIPT = `${BASE_URL}/api/v1/receipt/download`


//Slots
export const API_URL_GET_SLOTS_EFFICIENCY = `${BASE_URL}/api/v1/slot/efficiency`;
export const API_URL_GET_SLOTS_BLOCKING = `${BASE_URL}/api/v1/slot/efficiency/blocking`;
export const API_URL_GET_SLOTS_LIVE_BLOCKING = `${BASE_URL}/api/v1/slot/efficiency/blocking/live`;
export const API_URL_GET_ALL_SLOTS = `${BASE_URL}/api/v1/slots/get`
export const API_URL_GENERATE_SLOTS = `${BASE_URL}/api/v1/slots/save`
export const API_URL_GET_ALL_SLOTS_ZONE = `${BASE_URL}/api/v1/slots/zone/get`
export const API_URL_GET_ZONE_AIRPORTS = `${BASE_URL}/api/v1/stats/get/airports`
export const API_URL_GET_EXTRA_SLOTS = `${BASE_URL}/api/v1/slots/get/extra`
export const API_URL_SAVE_EXTRA_SLOTS = `${BASE_URL}/api/v1/slots/save/extra`
//export const API_URL_SLOTS_GET_REGIONS = `${BASE_URL}/api/v1/slots/regions`
export const API_URL_SLOTS_GET_REGIONS = `${BASE_URL}/api/v1/slots/zone/regions`
export const API_URL_CONCURRENT_RIDE_LIMITS =`${BASE_URL}/api/v1/slots/concurrent-ride-limit`
export const API_URL_CONCURRENT_RIDE_LIMITS_ZONE =`${BASE_URL}/api/v1/slots/zone/concurrent-ride-limit`

//slot's stats
//export const API_URL_SLOTS_STATS = `${BASE_URL}/api/v1/stats/slots`
export const API_URL_SLOTS_STATS_NEW = `${BASE_URL}/api/v1/stats/zone/slots/v1`;
export const API_VISUAL_SLOTS_BOUND_DATA = `${BASE_URL}/api/v1/slots/view/zone/bounds-data`;
export const API_SLOTS_ZONE_DATA = `${BASE_URL}/api/v1/slots/view/zone/slots-data`;
export const API_RFS_DATA = `${BASE_URL}/api/v1/stats/rfs/count`;

export const API_URL_SLOTS_STATS = `${BASE_URL}/api/v1/stats/zone/slots`

//export const API_URL_EXTRA_SLOTS_STATS = `${BASE_URL}/api/v1/stats/slots/extra`
export const API_URL_EXTRA_SLOTS_STATS = `${BASE_URL}/api/v1/stats/zone/slots/extra`
//export const API_URL_PREMIUM_SLOTS =`${BASE_URL}/api/v1/stats/slots/premium`;
export const API_URL_PREMIUM_SLOTS =`${BASE_URL}/api/v1/stats/zone/slots/premium`;

export const API_URL_SLOTS_STATS_V2 = `${BASE_URL}/api/v1/stats/slots/v2`
export const API_RIDER_BLU_WALLET_REFUND = `${BASE_URL}/api/v1/rider/blu/wallet/refund`;

export const API_JOURNEY_VEHCILE = `${BASE_URL}/api/v1/rider/blu/wallet/refund`;
export const API_JOURNEY_DRIVER = `${BASE_URL}/api/v1/rider/blu/wallet/refund`;
export const API_DRIVER_JOURNEY = `${BASE_URL}/api/v1/driver/get/journey`;
export const API_VEHICLE_JOURNEY = `${BASE_URL}/api/v1/vehicle/journey/details`;

//customer Dashboard
export const API_RIDE_DETAILS = `${BASE_URL}/api/v1/ride/panel`;
export const API_RIDER_DETAILS = `${BASE_URL}/api/v1/rider/retention`;
export const API_UPDATE_AGENT_DATA = `${BASE_URL}/api/v1/rider/communication/agent/add`;

// overpeeding
export const API_OVERSPEEDING_DRIVER = `${BASE_URL}/api/v1/driver/overspeeding/stats`;
//overSpeeding report
export const API_OVERSPEEDING_REPORT = `${BASE_URL}/api/v1/driver/overspeeding/report`
export const API_OVERSPEEDING_REPORT_PANEL = `${BASE_URL}/api/v1/driver/overspeeding/report/panel/`

//Recurring Rides
export const API_RECURRING_RIDES = `${BASE_URL}/api/v1/recurring/all/rides`;
export const API_RECURRING_WEEKLY_RIDES = `${BASE_URL}/api/v1/recurring/weekly/rides`;
export const API_RECURRING_RIDES_PANEL = `${BASE_URL}/api/v1/recurring/panel`;
export const API_RECURRING_RENEW_RIDE = `${BASE_URL}/api/v1/recurring/renew?recurringId=`;
export const API_RECURRING_CREATE_RIDE = `${BASE_URL}/api/v1/recurring/create`;
export const API_RECURRING_RIDE_COUNT = `${BASE_URL}/api/v1/recurring/ride/count`;

//Dummy Rides
export const API_DUMMY_RIDES =`${BASE_URL}/api/v1/ride/all/phantom/rides`;
export const API_DUMMY_RIDES_COUNT =`${BASE_URL}/api/v1/stats/phantom/rides`;

//OverWriting Fare
export const API_GET_OVERWRITING_FARE = `${BASE_URL}/api/v1/overwriting-fair-price`
export const API_GET_OVERWRITING_FARE_DISTANCE = `${BASE_URL}/api/v1/overwriting-fair-distance`
export const API_GET_SOURCE = `${BASE_URL}/api/v1/overwriting-fair-price/source`

//flags service
export const API_UPLOAD_FLAGS_CSV = `${BASE_URL}/api/v1/flags/upload/csv`;
export const API_NON_BUSINESS_SOURCE = `${BASE_URL}/api/v1/booking/partner/all`;
export const API_UPLOAD_BLUKMS_CSV = `${BASE_URL}/api/v1/test/blukms/extra/points`;
export const API_UPLOAD_BULK_REFUNDS = `${BASE_URL}/api/v1/rider/blu/wallet/bulk/refund`;
//block regions api
export const API_GET_BLOCK_REGION_ALL = `${BASE_URL}/api/v1/block-region`
export const API_CREATE_BLOCK_REGION = `${BASE_URL}/api/v1/block-region`
export const API_DELETE_BLOCKED_REGION = `${BASE_URL}/api/v1/block-region`
export const API_UPDATE_BLOCKED_REGION = `${BASE_URL}/api/v1/block-region`

//Region Controller
export const API_GET_BOUNDS = `${BASE_URL}/api/v1/region`
export const API_GET_BOUNDS_BY_ID = `${BASE_URL}/api/v1/region`
export const API_POST_BOUNDS_WITH_REGION_NAME = `${BASE_URL}/api/v1/region/bounds`
export const API_GET_COORDINATES_BY_BOUND_ID = `${BASE_URL}/api/v1/region/bounds`
export const API_POST_UPLOAD_KML_FILE = `${BASE_URL}/api/v1/region/upload_kml`
export const API_GET_BOUNDS_BY_KML = `${BASE_URL}/api/v1/dynamic-expansion/region/view`
//New Regions API's
export const API_GET_ALL_NEW_REGIONS_BY_TYPE = `${BASE_URL}/api/v1/dynamic-expansion/region`

//user
export const API_FETCH_ALL_USER = `${BASE_URL}/api/v1/user/fetch/all`

//to get all active hub
export const API_URL_ALL_ACTIVE_HUBS = `${BASE_URL}/api/v1/hub/all/active`
export const API_URL_ALL_HUB_DATA =`${BASE_URL}/api/v1/hub/get/hub/data`
//paymentEDC
export const API_REQUEST_PAYMENT = `${BASE_URL}/api/v1/payments/paytm/request`
export const API_STATUS_PAYMENT = `${BASE_URL}/api/v1/payments/paytm/status`
export const API_EDC_DEVICES = `${BASE_URL}/api/v1/payments/paytm/edc/devices`


export const API_RIDE_DEALY =`${BASE_URL}/api/v1/ride/delays`
export const API_DRIVER_CTA_MARK = `${BASE_URL}/api/v1/ride/driver/accepted/cta`
export const API_HARD_BLOCK_UPDATE =`${BASE_URL}/api/v1/supply-rebalancing/hardblock/toggle/action`


